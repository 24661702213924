<template>
  <div>
    <col id="data-loan-request" data-app />
    <LoginStep
      :card="card_index"
      :card-login="card_login"
      :card-send="card_send"
      :title="title"
      @setMailForm="setMailForm"
      @setIndex="setIndex"
      @previusBtn="previus"
      @check-user="checkUserType"
    />
    <v-main v-show="card_index === card_data">
      <v-form ref="form" v-model="valid2">
        <v-row class="align-center align-content-center">
          <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
            <v-row>
              <v-col class="col-md-12" align-self="center">
                <h2>Completá tus datos</h2>
              </v-col>
            </v-row>
            <v-row
              align="center"
              align-content="center"
              class="stepper-register__left-section-image"
            >
              <v-col align-self="center">
                <v-img
                  src="@/assets/img/yellow_pet_glass.svg"
                  alt="logo"
                  position="center center"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-md-6 align-self-center col-sm-12">
            <v-row class="loan-request__row-input--margin">
              <v-col>
                <v-text-field
                  v-model="form.dni"
                  label="Nro. de Documento de Identidad (DNI)"
                  hint="Sin puntos. Ejemplo: 28787992"
                  color="#FFDD00"
                  persistent-hint
                  type="number"
                  required
                  :rules="[rules.counterDNI, rules.required]"
                  @keypress="isNumber($event)"
                />
              </v-col>
            </v-row>
            <v-row class="loan-request__row-input--margin">
              <v-col>
                <v-text-field
                  v-model="form.phone"
                  label="Nro. de celular"
                  hint="Cód. de Área sin incluir ceros ni el 15. Ejemplo: 1155446677"
                  color="#FFDD00"
                  persistent-hint
                  type="number"
                  required
                  :rules="[rules.counterCel, rules.required]"
                  @keypress="isNumber($event)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          class="
            align-center align-content-center
            justify-center
            align-md-end align-content-md-end
            justify-md-end
            loan-request__row-btn--margin
          "
        >
          <v-col class="col-md-4 col-lg-4" align-self="center" align="center">
            <v-btn
              depressed
              rounded
              x-large
              class="btnAmarillo"
              :disabled="!valid2"
              right
              @click="validateUser()"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-main>

    <v-main v-show="card_index === card_check_identity">
      <v-form ref="form" v-model="valid4">
        <v-row class="align-center align-content-center">
          <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
            <v-row>
              <v-col class="col-md-12" align-self="center">
                <h2>Confirmá tus datos</h2>
              </v-col>
            </v-row>
            <v-row
              align-content="center"
              class="stepper-confirm__left-section-image"
            >
              <v-col class="col-md-12" align-self="center">
                <v-img
                  src="@/assets/img/yellow_pet_ok.svg"
                  alt="pet_ok"
                  position="center center"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="col-md-6 align-self-center col-sm-12 step-confirm__box-data"
          >
            <div class="boxData">
              <v-row>
                <v-col align="center" justify="center">
                  <h4>Apellido y Nombre:</h4>
                  <h3 class="boldMountedText marginTopText">
                    {{ form.fullname }}
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" justify="center">
                  <h4>CUIT/L:</h4>
                  <h3 class="boldMountedText marginTopText">
                    {{
                      form.cuit.toString().substr(0, 2) +
                      "-" +
                      form.cuit.toString().substr(2, 8) +
                      "-" +
                      form.cuit.toString().substr(10, 1)
                    }}
                  </h3>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            justify="right"
            class="
              col-md-3
              offset-md-5
              order-md-0 order-1
              col-sm-2
              offset-sm-2
              step-confirm__btn-not-person
            "
          >
            <v-btn
              bottom
              depressed
              rounded
              x-large
              block
              class="btnTransparente"
              @click="failed()"
            >
              No soy esa persona
            </v-btn>
          </v-col>
          <v-col
            align="right"
            justify="right"
            class="
              col-md-3
              offset-md-1
              order-md-1 order-0
              step-confirm__btn-continue
              col-sm-2
              offset-sm-2
            "
          >
            <v-btn
              bottom
              depressed
              rounded
              x-large
              block
              class="btnAmarillo"
              @click="confirmData()"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-main>

    <v-main v-show="card_index === card_loading_mail" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
} from "firebase/auth";
import LoginStep from "@/components/steps/LoginStep";
import { userRoute } from "@/helper/userRoute";

export default {
  name: "AutogestionDatosSolicitud",
  components: { LoginStep },
  props: {
    card: { type: Number, default: 1 },
    title: { type: String, default: "Registrarse" },
  },
  data: () => ({
    timeout: 4000,
    tipos_documentos: [
      { id: "DNI", detalle: "DNI" },
      { id: "LC", detalle: "LC" },
      { id: "LE", detalle: "LE" },
      { id: "CI", detalle: "CI" },
      { id: "PAS", detalle: "PASAPORTE" },
    ],
    valid: true,
    valid2: true,
    valid3: true,
    valid4: true,
    rows: 5,
    card_index: "",
    card_login: 1,
    card_data: 2,
    card_send: 3,
    card_check_identity: 4,
    mailForm: false,
    form: {
      dni: "",
      mail: "xxx@xxx.com",
      phone: "",
      fullname: "",
      cuit: "",
      genre: "",
      name: "",
      lastName: "",
      birthday: "",
    },
    is_device: false,
    playstore:
      "http://play.google.com/store/apps/details?id=ar.com.findosiftsa",
    sn_google: "google",
    sn_facebook: "facebook",
    sn_twitter: "twitter",
    isOk: true,
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      counterDNI: (value) =>
        (value !== null && (value.length === 7 || value.length === 8)) ||
        "Este campo debe contener entre 7 y 8 caracteres sin puntos. Ejemplo: 28787992.",
      counterCel: (value) =>
        (value !== null && value.length === 10) ||
        "Este campo debe contener 10 caracteres.",
      validateMail: (v) =>
        /.+@.+\..+/.test(v) || "Este campo debe ser un e-mail válido.",
    },
    formHasErrors: false,
    error_identity: false,
    sendMail: false,
    messageDifferentSocialMedia:
      "Ya te habías registrado con otra red social. Por favor, utiliza la misma para poder continuar.",
    fbLogin: false,
    card_loading_mail: 5,
    client_msg:
      "Ya sos cliente Prester. Vamos a mostrarte las opciones que tenés para que puedas gestionar y pedir nuevos préstamos!",
    not_client_msg:
      "<b>Tu registro no figura en la base de clientes.</b><br> Si querés solicitar un préstamo, hacé clic en “Continuar”",
  }),
  watch: {
    created() {
      this.$store.commit("SET_DEVICE");
    },
  },
  mounted() {
    this.form.mail = this.$store.getters.getEmail;
    this.card_index = this.card;
    this.initialized();
    this.fbLogin = process.env.VUE_APP_LOGIN_FACEBOOK === "1";
  },

  methods: {
    ...mapActions([
      "setClient",
      "openLoader",
      "closeLoader",
      "setEmail",
      "logIn",
      "logout",
      "goWarningModal",
      "setWarningMessageModal",
      "setTxtBtnWarning",
      "setTxtBtnWarningStay",
      "openInformation",
      "closeInformation",
      "isAvailableUser",
    ]),
    next() {
      this.$emit("send-message", "2");
    },
    initialized() {
      this.checkLoginByLink();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    validateUser() {
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `WEB_ARG_DNI`,
        id_person: this.$store.getters.getUserForm,
      });
      this.openLoader();

      this.$apiserver
        .savePhone(`${this.$business.PREFFIX_PHONE}${this.form.phone}`)
        .then((data3) => {
          this.$apiserver
            .validateData(this.form.dni)
            .then((data) => {
              this.form.fullname = data.denominacion;
              this.form.cuit = data.cuitCuil;
              this.form.genre = data.sexo;
              //this.card_index = this.card_check_identity;
              let userForm = this.getUserForm();
              this.$apiserver
                .setDataUser(userForm)
                .then((data2) => {
                  this.card_index = this.card_check_identity;
                })
                .catch((e) => {
                  this.$store.commit(
                    "SET_ERROR_MODAL_MSG",
                    "Ocurrió un error," +
                      " volvé a intentarlo y si el problema persiste intenta más tarde o contactate por WhatsApp \n" +
                      " con nuestro equipo de soporte."
                  );
                  this.$store.commit("ERROR_MODAL", true);
                  console.log(e);
                  this.error_identity = true;
                  console.log("No pudimos obtener tus datos");
                })
                .finally(() => {
                  this.closeLoader();
                });
            })
            .catch((e) => {
              this.$store.commit(
                "SET_ERROR_MODAL_MSG",
                "Ocurrió un error," +
                  " volvé a intentarlo y si el problema persiste intenta más tarde o contactate por WhatsApp \n" +
                  " con nuestro equipo de soporte."
              );
              this.$store.commit("ERROR_MODAL", true);
              console.log(e);
              this.error_identity = true;
              console.log("No pudimos obtener tus datos");
              this.closeLoader();
            })
            .finally(() => {});
        })
        .catch((e) => {
          this.$store.commit(
            "SET_ERROR_MODAL_MSG",
            "Ocurrió un error," +
              " volvé a intentarlo y si el problema persiste intenta más tarde o contactate por WhatsApp \n" +
              " con nuestro equipo de soporte."
          );
          this.$store.commit("ERROR_MODAL", true);
          console.log(e);
          this.error_identity = true;
          console.log("No pudimos obtener tus datos");
        })
        .finally(() => {
          //this.closeLoader();
        });
    },
    previus() {
      this.card_index = this.card_login;
    },
    failed() {
      this.card_index = this.card_data;
      this.$refs.formDni.reset();
    },
    goToStore() {
      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = this.playstore;
      } else {
        let routeData = this.$router.resolve({ name: "playstore" });
        window.open(routeData.href, "_blank");
      }
    },
    logOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          console.log("salida existosa");
        })
        .catch((error) => {
          console.log("salida incorrecta");
        });
    },
    askForEmail() {
      this.card_index = this.card_send;
    },
    confirmData() {
      this.openLoader(
        "Estamos procesando datos. Esto puede demorar algunos segundos"
      );
      this.$gtm.trackEvent({
        noninteraction: false,
        event: "WEB_ARG_CONFIRM_PERSON",
        id_person: this.$store.getters.getUserForm,
      });
      this.setClient(this.form);
      this.$apiserver
        .checkCredit(this.form.dni)
        .then((response) => {
          this.isAvailableUser(response.code);
          if (response.code === 2) {
            this.$router.replace({ name: "send_to_app" });
            return;
          }
          if (
            response.code !== 1 &&
            response.code !== null &&
            response.code !== -1
          ) {
            this.$router.replace({
              name: "request_failed",
              params: { codeDeny: response.code },
            });
          } else {
            this.form.name = response.firstName;
            this.form.lastName = response.lastName;
            this.form.birthday = response.birthday;
            this.setClient(this.form);
            let userForm = this.getUserForm();
            this.$apiserver.setInfoUser(userForm);
            this.$gtm.trackEvent({
              noninteraction: false,
              event: "WEB_ARG_CREDIT_APPROVED",
              id_person: this.$store.getters.getUserForm,
            });
            this.next();
          }
        })
        .catch((e) => {
          this.isAvailableUser(-1);
          this.$store.commit(
            "SET_ERROR_MODAL_MSG",
            "Ocurrió un error," +
              " volvé a intentarlo y si el problema persiste intenta más tarde o contactate por WhatsApp \n" +
              " con nuestro equipo de soporte."
          );
          this.$store.commit("ERROR_MODAL", true);

          this.$gtm.trackEvent({
            noninteraction: false,
            event: "WEB_ARG_ERROR_CONFIRM_PERSON",
            id_person: this.$store.getters.getUserForm,
          });
          console.log(e);
        })
        .finally(() => {
          this.closeLoader();
        });
    },
    checkLoginByLink() {
      const auth = getAuth();
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Ingrese mail verificado");
        }
        this.form.mail = email;
        this.setEmail(this.form.mail);
        this.openLoader();
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForSignIn");
            const user = result.user;
            let pwd = null;
            user.getIdToken().then((tk) => {
              let fbkId = tk.slice(0, 255);
              const msg = this.$apiserver.loginServer(user.uid, fbkId, tk);
              const data = () => {
                msg
                  .then((data) => {
                    pwd = data;
                    this.$gtm.trackEvent({
                      noninteraction: false,
                      event: "WEB_ARG_LOGIN",
                      id_person: user.uid,
                    });
                    let info = {
                      type: "mail",
                      email: this.form.mail,
                      name: user.displayName
                        ? user.providerData[0].displayName
                        : user.displayName,
                    };
                    return this.$apiserver.sendFormLoginUser(
                      info,
                      fbkId,
                      user.uid
                    );
                  })
                  .then(() => {
                    this.$store.commit("SET_USER_FORM", user.uid);
                    this.$apiserver.isRegistered().then((result) => {
                      if (!result.hasOwnProperty("registered")) {
                        throw new Error("ERROR-not_status_user");
                      }
                      let isRegistered = result.registered;
                      this.checkUserType(
                        isRegistered,
                        this.$route.query.signup === "true",
                        user.uid,
                        pwd
                      );
                    });
                  })
                  .catch((error) => {
                    this.closeLoader();
                    this.$store.commit("ERROR_MODAL", true);
                    console.log(error.message);
                  });
              };
              data();
            });
          })
          .catch((error) => {
            this.logout();
            this.closeLoader();
            this.$store.commit("ERROR_MODAL", true);
            this.$router.push("/registro/ingreso");
            window.localStorage.removeItem("emailForSignIn");
            console.log(error);
          });
      }
    },
    getUserForm() {
      return {
        dni: this.form.dni,
        cuit: this.form.cuit,
        firstName: this.form.name === "" ? this.form.fullname : this.form.name,
        lastName:
          this.form.lastName === "" ? this.form.fullname : this.form.lastName,
        name: this.form.firstName,
        sex: this.form.genre,
        email: this.form.mail,
        birthday: this.form.birthday,
      };
    },
    setIndex(code) {
      this.card_index = code;
    },
    getCardSend() {
      return this.card_send;
    },
    setMailForm(mail) {
      this.form.mail = mail;
    },
    checkUserType(isRegistered, clientLogin, id, pwd) {
      let clientAndGetLoan =
        isRegistered &&
        clientLogin &&
        process.env.VUE_APP_HOME_USER_FEATURE === "1";
      let clientAndHomeUser =
        isRegistered &&
        !clientLogin &&
        process.env.VUE_APP_HOME_USER_FEATURE === "1";
      let newUserGoHome = !isRegistered && !clientLogin;
      let newUserGetLoan = !isRegistered && clientLogin;
      this.closeLoader();
      if (process.env.VUE_APP_HOME_USER_FEATURE === "0" && isRegistered) {
        this.$router.replace({ name: "request_failed" });
        return;
      }
      if (clientAndGetLoan) {
        let actionBtn = () =>
          this.$router.push({ name: userRoute.STATUS_LOAN });
        let argInformation = {
          message: this.client_msg,
          action: actionBtn,
        };
        this.openInformation(argInformation);
        this.logIn(pwd);
        setTimeout(() => this.goUserHome(), 3000);
      }

      if (clientAndHomeUser) {
        this.logIn(pwd);
        this.$router.push({ name: userRoute.STATUS_LOAN });
      }

      if (newUserGetLoan) {
        this.logIn(null);
        this.$gtm.trackEvent({
          noninteraction: false,
          event: "WEB_ARG_LOGIN",
          id_person: id,
        });
        this.$router.push({ name: "request_new_loan" });
      }
      if (newUserGoHome) {
        this.goWarningModal(true);
        this.setWarningMessageModal(this.not_client_msg);
        this.setTxtBtnWarning("Continuar");
        this.setTxtBtnWarningStay("Reintentar");
      }
    },
    goUserHome() {
      this.closeInformation();
      this.$router.push({ name: userRoute.STATUS_LOAN });
    },
  },
};
</script>
<style>
.right-input >>> input {
  text-align: right;
}
.chip-indicadores {
  color: "blue-grey--text darken-4";
  font-size: 14px;
  font-style: normal;
}

.primary-change {
  color: #fff !important;
  background-color: #105cfa !important;
  font-size: 20px;
  font-weight: bold;
  height: 70px !important;
  display: flex;
  align-items: center;
}

.v-dialog {
  box-shadow: 0px !important;
}

style > .inputFieldCelular .v-text-field__slot input {
  border-color: #ffdd00 !important;
  font-weight: bold !important;
  color: #6031da !important;
  font-size: 1.5625rem !important;
  text-align: center !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #ffdd00;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: medium 0 0 0;
}

.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: #ffdd00;
}

.v-input__is-focused {
  border-color: #ffdd00 !important;
}

.v-input__slot {
  font-size: 25px !important;
}

.v-text-field.centered-input .v-label {
  left: 50% !important;
  transform: translateY(-20px) translateX(-50%) !important;
}

.v-toolbar__content {
  color: #000000 !important;
}
</style>
