<template>
  <div>
    <v-main v-show="card_index === cardLogin">
      <v-row align="center" align-content="center" justify="center">
        <v-col align-self="center" justify="center" cols="12" sm="6" md="6">
          <div class="stepper__left-section-title">
            <p class="text-md-h4 text-h5 font-weight-medium">
              {{ title }}
            </p>
            <p class="subtitle">ELEGÍ LA OPCION QUE PREFIERAS</p>
          </div>
          <v-col align="center" justify="center" class="stepper__main-image">
            <v-img
              class="imageCircleDesktop"
              src="@/assets/img/yellow_pet_pen.svg"
              alt="logo"
              contain
              max-width="80%"
            />
          </v-col>
        </v-col>
        <v-col align-self="center" justify="center" cols="12" sm="6" md="6">
          <div class="stepper__right-section">
            <v-row v-show="fbLogin">
              <v-col align="center" justify="center">
                <v-btn
                  color="#1877F2"
                  block
                  rounded
                  dark
                  @click="loginSocialNetwork(sn_facebook)"
                >
                  <v-icon left dark> mdi-facebook </v-icon>
                  Facebook
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center" justify="center">
                <v-btn
                  color="#FFFFFF"
                  block
                  rounded
                  @click="loginSocialNetwork(sn_google)"
                >
                  <img
                    id="imageIconGoogle"
                    src="@/assets/img/icon-logo-google.png"
                    alt="google"
                  />
                  Google
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center" justify="center">
                <v-btn
                  color="#1D9BF0"
                  block
                  rounded
                  dark
                  @click="loginSocialNetwork(sn_twitter)"
                >
                  <v-icon left dark> mdi-twitter </v-icon>
                  Twitter
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center" justify="center">
                <v-btn
                  color="#00A09B"
                  block
                  rounded
                  dark
                  @click="askForEmail()"
                >
                  <v-icon left dark> mdi-email-outline </v-icon>
                  Tu e-mail
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-main>
    <v-main v-show="card_index === cardSend">
      <v-form ref="form" v-model="validateMail">
        <v-row align-content="center" align="center">
          <v-col justify="center" cols="12" sm="6" md="6">
            <div>
              <h2>Completá tus datos</h2>
            </div>
            <v-col
              align="center"
              justify="center"
              class="stepper-confirm__left-section-image"
            >
              <v-img
                class="imageCircleDesktop"
                src="@/assets/img/yellow_pet_pen.svg"
                alt="logo"
                contain
              />
            </v-col>
          </v-col>
          <v-col
            class="step-mail__input-margin"
            align-self="center"
            justify="center"
            cols="12"
            sm="6"
            md="6"
          >
            <v-row align-content="center" align="center">
              <v-col justify="center" align-self="center">
                <v-text-field
                  v-model="mailForm"
                  label="Dirección de email"
                  hint="Ej. prester.argentina@gmail.com"
                  persistent-hint
                  required
                  :rules="[rules.validateMail, rules.required]"
                  color="#FFDD00"
                  tabindex="1"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          class="step-mail__btn-margin"
          align-md="end"
          align-content-md="end"
          align-content="center"
          align="center"
        >
          <v-col md="7" sm="7" cols="12" />
          <v-col md="2" sm="2" cols="12" order-md="0" order-sm="0" order="1">
            <v-btn
              bottom
              depressed
              rounded
              x-large
              block
              class="btnTransparente"
              tabindex="3"
              @click="$emit('previusBtn')"
            >
              Atrás
            </v-btn>
          </v-col>
          <v-col md="3" order-md="1" order-sm="1" order="0">
            <v-btn
              bottom
              depressed
              rounded
              x-large
              block
              class="btnAmarillo"
              :disabled="!validateMail"
              tabindex="2"
              @click="loginVerifyMail()"
            >
              Enviar mail
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-main>
    <v-dialog v-model="modalLoginByMail" persistent width="500">
      <v-card>
        <div align="center" style="height: 200px; padding-top: 20px">
          <v-img
            class="imageCircleDesktopLoading"
            src="@/assets/img/yellow_pet_glass.svg"
            alt="logo"
            width="50"
            contain
          >
          </v-img>
        </div>
        <div style="height: 110px; margin-top: 15px">
          <h4 class="pl-4 pr-4 mb-4">
            ¡Gracias por registrarte! <br />Por favor revisá tu casilla donde te
            enviamos un mail para continuar con tu solicitud.
          </h4>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginStep",
  props: ["mail", "title", "card", "cardSend", "cardLogin"],
  data: () => ({
    sn_google: "google",
    sn_facebook: "facebook",
    sn_twitter: "twitter",
    validateMail: true,
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      validateMail: (v) =>
        /.+@.+\..+/.test(v) || "Este campo debe ser un e-mail válido.",
    },
    mailLogin: "",
    modalLoginByMail: false,
    messageDifferentSocialMedia:
      "Ya te habías registrado con otra red social. Por favor, utiliza la misma para poder continuar.",
    msgLoadLogin: "Ingresá los datos de tu cuenta",
    fbLogin: false,
  }),
  computed: {
    mailForm: {
      get() {
        return this.mailLogin;
      },
      set(val) {
        this.mailLogin = val;
        this.$emit("setMailForm", val);
      },
    },
    card_index: {
      get() {
        return this.card;
      },
      set(val) {
        this.$emit("setIndex", val);
      },
    },
  },
  created() {
    this.mailLogin = this.mail;
    this.setGoSignUp(this.title === "Registrarse");
    this.fbLogin = process.env.VUE_APP_LOGIN_FACEBOOK === "1";
  },
  methods: {
    ...mapActions([
      "openLoader",
      "closeLoader",
      "logIn",
      "logout",
      "goWarningModal",
      "setWarningMessageModal",
      "setTxtBtnWarning",
      "setTxtBtnWarningStay",
      "openInformation",
      "closeInformation",
      "setEmail",
      "setGoSignUp",
    ]),
    ...mapGetters(["getGoSignup"]),
    getProvider(socialNetwork) {
      let provider = "";
      switch (socialNetwork) {
        case this.sn_google:
          provider = new GoogleAuthProvider();
          break;
        case this.sn_facebook:
          provider = new FacebookAuthProvider();
          break;
        case this.sn_twitter:
          provider = new TwitterAuthProvider();
          break;
      }
      return provider;
    },
    getTkLogin(socialNetwork, result) {
      let credential = "";
      switch (socialNetwork) {
        case "google":
          credential = GoogleAuthProvider.credentialFromResult(result);
          break;
        case "twitter":
          credential = TwitterAuthProvider.credentialFromResult(result);
          break;
        case "facebook":
          credential = FacebookAuthProvider.credentialFromResult(result);
          break;
      }
      return credential ? credential.accessToken : null;
    },
    getSocialForm(socialNetwork) {
      let credential = null;
      switch (socialNetwork) {
        case "google":
          credential = "Google";
          break;
        case "twitter":
          credential = "Twitter";
          break;
        case "facebook":
          credential = "Facebook";
          break;
      }
      return credential;
    },
    loginSocialNetwork(socialNetwork) {
      const auth = getAuth();
      const provider = this.getProvider(socialNetwork);
      //this.openLoader(this.msgLoadLogin);
      signInWithPopup(auth, provider)
        .then((result) => {
          this.openLoader();
          const token = this.getTkLogin(socialNetwork, result);
          const user = result.user;
          let mailForm = user.providerData[0].email;
          let pwd = null;
          this.$emit("setMailForm", mailForm);
          user.getIdToken().then((tk) => {
            let fcmTk = tk.slice(0, 255);
            const msg = this.$apiserver.loginServer(user.uid, fcmTk, tk);
            const data = () => {
              msg
                .then((data) => {
                  pwd = data;
                  let info = {
                    type: this.getSocialForm(socialNetwork),
                    email: mailForm,
                    name: user.providerData[0].displayName,
                  };
                  return this.$apiserver.sendFormLoginUser(
                    info,
                    fcmTk,
                    user.uid
                  );
                })
                .then(() => {
                  this.setEmail(mailForm);
                  this.$store.commit("SET_USER_FORM", user.uid);
                  return this.$apiserver.isRegistered();
                })
                .then((result) => {
                  if (!result.hasOwnProperty("registered")) {
                    throw new Error("ERROR-not_status_user");
                  }
                  let isRegistered = result.registered;
                  this.$emit(
                    "check-user",
                    isRegistered,
                    this.title === "Registrarse",
                    user.uid,
                    pwd
                  );
                })
                .catch((error) => {
                  this.$store.commit("ERROR_MODAL", true);
                  console.log(error.message);
                  this.closeLoader();
                });
            };
            data();
          });
        })
        .catch((error) => {
          this.closeLoader();

          if (
            error.message === "Firebase: Error (auth/popup-closed-by-user)."
          ) {
            return;
          }
          if (
            error.message ===
            "Firebase: Error (auth/account-exists-with-different-credential)."
          ) {
            this.$store.commit(
              "SET_ERROR_MODAL_MSG",
              this.messageDifferentSocialMedia
            );
          }
          this.$store.commit("ERROR_MODAL", true);
          console.log(error.message);
        });
    },
    askForEmail() {
      this.card_index = this.cardSend;
    },
    loginVerifyMail() {
      this.sendMail = true;
      let clientSingUp = this.getGoSignup();
      const actionCodeSettings = {
        url: process.env.VUE_APP_URL + `/registro/mail?signup=${clientSingUp}`,
        handleCodeInApp: true,
        iOS: {
          bundleId: "com.example.ios",
        },
        android: {
          packageName: "com.example.android",
          installApp: true,
          minimumVersion: "12",
        },
        dynamicLinkDomain: "findo.page.link",
      };
      this.sendLink(actionCodeSettings);
      this.modalLoginByMail = true;
    },
    sendLink(actionCodeSettings) {
      const auth = getAuth();
      const email = this.mailForm;
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          this.logIn();
        })
        .catch((error) => {
          let isUserClosePopUp =
            error.message === "Firebase: Error (auth/popup-closed-by-user).";
          let isUserExist =
            error.message ===
            "Firebase: Error (auth/account-exists-with-different-credential).";
          if (isUserClosePopUp) {
            return;
          }
          this.logout();
          if (isUserExist) {
            this.$store.commit(
              "SET_ERROR_MODAL_MSG",
              this.messageDifferentSocialMedia
            );
          }
          this.$store.commit("ERROR_MODAL", true);
          console.log(error.message);
        });
    },
  },
};
</script>

<style scoped>
</style>